export default [
  {
    key: 'description',
    label: 'field.description',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'isActive',
    label: 'status.active',
    type: 'checkbox',
    cols: 12,
  },
]
