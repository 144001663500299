<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <validation-observer ref="editForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="editForm"
        @submit="submit"
        :form="$refs.editForm"
        :disabled="invalid"
      >
        <b-row>
          <b-col cols="12">
            <b-card no-body class="p-2" v-if="!loadingPreOrderStatus">
              <div class="m-0-5">
                <b-row>
                  <b-col>
                    <h3>
                      {{ $t('general.preOrderConfig') }}
                      <span class="text-primary" v-if="data.isActive">{{
                        $t('status.active')
                      }}</span>
                      <span class="text-primary" v-else>{{
                        $t('status.inactive')
                      }}</span>
                    </h3>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <b-card>
              <n-input :initValue="initData" :fields="fields" v-model="data">
                <template #description="item">
                  <b-form-group
                    :label-for="item.field.key"
                    v-if="item.field.type !== 'divider'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="$t(item.field.label)"
                      :rules="item.field.rules"
                    >
                      <b-card-code :title="$t(item.field.label)">
                        <quill-editor
                          v-model="data.description"
                          :options="editorOption"
                        >
                          <div id="toolbar" slot="toolbar">
                            <!-- Add a bold button -->
                            <button class="ql-bold">Bold</button>
                            <button class="ql-italic">Italic</button>

                            <!-- Add font size dropdown -->
                            <select class="ql-size">
                              <option value="small" />
                              <!-- Note a missing, thus falsy value, is used to reset to default -->
                              <option selected />
                              <option value="large" />
                              <option value="huge" />
                            </select>
                          </div>
                        </quill-editor>

                        <template #code>
                          {{ codeBubble }}
                        </template>
                      </b-card-code>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </n-input>
            </b-card>
          </b-col>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
              v-if="$can('update', 'store')"
            >
              {{ $t('button.saveChanges') }}
            </n-button-loading>
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t('button.back') }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import formInput from './formInput'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import { codeBubble } from './code'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

const EventRepository = Repository.get('event')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
    BButton,
    BCardHeader,
    BCardBody,
    quillEditor,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        description: null,
        isActive: false,
      },
      loading: false,
      required,
      codeBubble,
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true
          EventRepository.update(this.data)
            .then((response) => {
              this.$toast.success(response.data.message)
              this.$router.push({ name: 'list-preorderConfiguration' })
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.message)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    show() {
      EventRepository.index()
        .then((response) => {
          this.data = response.data.data
          this.initData = response.data.data
        })
        .catch()
        .then(() => {})
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...formInput]

    return { fields }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
